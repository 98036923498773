import {Twemoji} from "react-emoji-render";
import * as base64 from "base-64";
import {Picker} from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import {DateTime, Interval} from "luxon";
import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {
    Link,
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from "react-router-dom";
import Select from "react-select";
import {Flip, toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as utf8 from "utf8";
import {allMovies, Movie, pickRandomMovie} from "./movies";

const GUESSES_KEY = "guesses";

function decode(s: string) {
    return JSON.parse(utf8.decode(base64.decode(decodeURIComponent(s))));
}

function encode(o: any) {
    return encodeURIComponent(base64.encode(utf8.encode(JSON.stringify(o))));
}

function buildShareUrl(emoviToGuess: EmoviToGuess) {
    const encodedEmovi = encode(emoviToGuess);
    return `${window.location.origin}/guess/${encodedEmovi}`;
}

export function getDayString(shiftDayCount?: number) {
    return DateTime.now()
        .plus({days: shiftDayCount ?? 0})
        .toFormat("yyyy-MM-dd");
}

interface EmoviToGuess {
    id: Movie["id"];
    emojiText: string;
    author?: string;
}

const START_DATE = DateTime.fromISO("2022-07-17");

const DAILY_EMOVI: Record<string, EmoviToGuess> = {
    "2022-07-17": {id: "tt0114709", emojiText: "🥔🤠👨‍🚀🐊🐖🐶"},
    "2022-07-18": {id: "tt0167260", emojiText: "💍🌋🧙‍♂️👑"},
    "2022-07-19": {id: "tt0103639", emojiText: "🧞‍♂️🪔🐒👸🤴"},
    "2022-07-20": {id: "tt0211915", emojiText: "👩‍🦰🎠🗼🥖🇫🇷"},
    "2022-07-21": {id: "tt1745960", emojiText: "✈️🇺🇸🕶️"},
    "2022-07-22": {id: "tt0109830", emojiText: "🏃🍫🦐"},
    "2022-07-23": {id: "tt0120382", emojiText: "🙍‍♂️🎥⛵😨📺"},
    "2022-07-24": {id: "tt4633694", emojiText: "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"},
    "2022-07-25": {id: "tt0090605", emojiText: "👽🤰🩸😱"},
    "2022-07-26": {id: "tt0062622", emojiText: "👁️🖥️🛰️🚀"},
    "2022-07-27": {id: "tt0054215", emojiText: "🔪🚿🧓"},
    "2022-07-28": {id: "tt0101414", emojiText: "🌹👸🧌"},
    "2022-07-29": {id: "tt0264464", emojiText: "🏃‍♂️✈️💰🏃"},
    "2022-07-30": {id: "tt0045152", emojiText: "☔🎤😃"},
    "2022-07-31": {id: "tt0107290", emojiText: "🦟💉🥚🦕🦖🚨🏃‍♂️"},
    "2022-08-01": {id: "tt0993846", emojiText: "🐺🧱🛣️〽️🏦💵"},
    "2022-08-02": {id: "tt0382932", emojiText: "🐭💆‍♂️👨‍🍳🍲"},
    "2022-08-03": {id: "tt10648342", emojiText: "🦸🔨❤️➕⛈️"},
    "2022-08-04": {id: "tt0482571", emojiText: "🏃🚪🎩🚪🏃▶️👬"},
    "2022-08-05": {id: "tt2380307", emojiText: "🎸💀👦🇲🇽"},
    "2022-08-06": {id: "tt0107048", emojiText: "🔄⏰🦔"},
    "2022-08-07": {id: "tt0133093", emojiText: "🕵️🕵️‍♀️🤜🤵🔌💊"},
    "2022-08-08": {id: "tt1130884", emojiText: "👮‍♂️💊🏝️😨"},
    "2022-08-09": {id: "tt2293640", emojiText: "🤓🤓🤓🍌🧒"},
    "2022-08-10": {id: "tt0114369", emojiText: "😋😛😴🤢😡😎🤤👀"},
    "2022-08-11": {id: "tt5311514", emojiText: "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"},
    "2022-08-12": {id: "tt0119217", emojiText: "🧹🏫👨‍🏫🧠🍎"},
    "2022-08-13": {id: "tt12412888", emojiText: "🦔👟✌️"},
    "2022-08-14": {id: "tt0088763", emojiText: "🚗🔙⌚👨‍🔬🙍"},
    "2022-08-15": {id: "tt0435761", emojiText: "👨‍🚀🤠🏫🍓🧸"},
    "2022-08-16": {id: "tt0116629", emojiText: "🇺🇸🎆👽👊"},
    "2022-08-17": {id: "tt0325980", emojiText: "🏴‍☠️🌊💀⚔️⚫📿"},
    "2022-08-18": {id: "tt6467266", emojiText: "🐷🦍🦁🎹🎧🦔"},
    "2022-08-19": {id: "tt1160419", emojiText: "👩‍👦🗡️⏳🐛🪐"},
    "2022-08-20": {id: "tt0066921", emojiText: "👁️🎩🦯🥛🍊"},
    "2022-08-21": {id: "tt1049413", emojiText: "👴🧒🎈🏠🏞️🐕🔼"},
    "2022-08-22": {id: "tt2582802", emojiText: "🥁👨‍🦲⏱️🩸🚗📁🎶"},
    "2022-08-23": {id: "tt0120338", emojiText: "💑🚢🧊🥶👵"},
    "2022-08-24": {id: "tt2096673", emojiText: "😡😭😊🤢😱🧠"},
    "2022-08-25": {id: "tt0050083", emojiText: "😠😠😠😠😠😠😠😠😠😠😠😠"},
    "2022-08-26": {id: "tt1396484", emojiText: "🤡👿🎈"},
    "2022-08-27": {id: "tt0073195", emojiText: "🦈😱"},
    "2022-08-28": {id: "tt0137523", emojiText: "🥊♣️🧼"},
    "2022-08-29": {id: "tt1877830", emojiText: "🦇🐱🐧❓"},
    "2022-08-30": {id: "tt0088247", emojiText: "🤖🕶️🔫"},
    "2022-08-31": {id: "tt0110413", emojiText: "🧔🔫🥛👧"},
    "2022-09-01": {id: "tt0110912", emojiText: "💼🕺💉💃☕⌚"},
    "2022-09-02": {id: "tt0083658", emojiText: "🗡️🏃‍♂️"},
    "2022-09-03": {id: "tt4729430", emojiText: "📫🧓🔨🧸🛷"},
    "2022-09-04": {id: "tt2382320", emojiText: "🚫🕐💀"},
    "2022-09-05": {id: "tt1201607", emojiText: "👦⚡☠️⚔️2️⃣"},
    "2022-09-06": {id: "tt2024544", emojiText: "🕛🗓️⛓️"},
    "2022-09-07": {id: "tt0095016", emojiText: "🗼🎄👨🔫"},
    "2022-09-08": {id: "tt0041959", emojiText: "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"},
    "2022-09-09": {id: "tt0070047", emojiText: "✝️🤮🙏"},
    "2022-09-10": {id: "tt0266697", emojiText: "👱‍♀️⛩️⚔️"},
    "2022-09-11": {id: "tt0081505", emojiText: "🪓😈🛀"},
    "2022-09-12": {id: "tt10298810", emojiText: "👨‍🚀🌌"},
    "2022-09-13": {id: "tt0167404", emojiText: "👁️👀💀🧑‍🤝‍🧑"},
    "2022-09-14": {id: "tt9032400", emojiText: "🦸‍♂️🌍♾️"},
    "2022-09-15": {id: "tt0317705", emojiText: "👨‍👩‍👧‍👦🦸ℹ️"},
    "2022-09-16": {id: "tt6751668", emojiText: "👨‍👩‍👧‍👦💸🇰🇷"},
    "2022-09-17": {id: "tt0266543", emojiText: "🔎🐟"},
    "2022-09-18": {id: "tt4154756", emojiText: "♾️⚔️"},
    "2022-09-19": {id: "tt0075148", emojiText: "🥊"},
    "2022-09-20": {id: "tt0112573", emojiText: "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"},
    "2022-09-21": {id: "tt0816692", emojiText: "🚀🌊⏲️🌽"},
    "2022-09-22": {id: "tt0102926", emojiText: "🤐🐑"},
    "2022-09-23": {id: "tt0032138", emojiText: "🐶👠👠🌪️🏠"},
    "2022-09-24": {id: "tt0884328", emojiText: "🌫️🛒🌫️"},
    "2022-09-25": {id: "tt1375666", emojiText: "💤🌀⤴️"},
    "2022-09-26": {id: "tt0060196", emojiText: "👍👎👹"},
    "2022-09-27": {id: "tt0198781", emojiText: "🟢👁️🟢👧👹"},
    "2022-09-28": {id: "tt0892769", emojiText: "⚫🐉🦷"},
    "2022-09-29": {id: "tt0910970", emojiText: "🌍🗑️🤖🌱❤️"},
    "2022-09-30": {id: "tt8503618", emojiText: "🇺🇸🎼🗽🎵🔫"},
    "2022-10-01": {id: "tt0457430", emojiText: "👁️👐👁️"},
    "2022-10-02": {id: "tt0118715", emojiText: "🎳🥛💼🩲"},
    "2022-10-03": {id: "tt0169547", emojiText: "🇺🇸💅🌹🌹🌹🌹🌹🌹"},
    "2022-10-04": {id: "tt0056172", emojiText: "🏇🏜️🗡️"},
    "2022-10-05": {id: "tt1345836", emojiText: "🦇🤵⬆️"},
    "2022-10-06": {id: "tt0102926", emojiText: "🔇🐏"},
    "2022-10-07": {id: "tt0034583", emojiText: "🇫🇷💖🚂😒🕌☕🎹✈️"},
    "2022-10-08": {id: "tt1028532", emojiText: "🐶🙍‍♂️🚋⏱️"},
    "2022-10-09": {id: "tt0119698", emojiText: "🐺👸🩸🐗🐛"},
    "2022-10-10": {id: "tt0090605", emojiText: "🌐👩👧🐱💥👽"},
    "2022-10-11": {id: "tt0053291", emojiText: "👨‍🦰👨‍🦱👠💄👗"},
    "2022-10-12": {id: "tt0110357", emojiText: "🦁🐆🐗👑"},
    "2022-10-13": {id: "tt0245429", emojiText: "🐉😱👹👵⛩️"},
    "2022-10-14": {id: "tt0117951", emojiText: "💉👬🧑‍🤝‍🧑💊🇬🇧🤪🤮"},
    "2022-10-15": {id: "tt7657566", emojiText: "👨🔎🇪🇬⛴️☠️"},
    "2022-10-16": {id: "tt0096283", emojiText: "▶️🚪🏠🐻"},
    "2022-10-17": {id: "tt4513678", emojiText: "🙍🙍🙍🙍👻🧹"},
    "2022-10-18": {id: "tt1464335", emojiText: "🗺️🧗‍♂️🚁💰"},
    "2022-10-19": {id: "tt0338013", emojiText: "💑💔😭💵👨‍🔬😴🧠💻🕵️🙂"},
    "2022-10-20": {id: "tt0068646", emojiText: "🐴🛏️🩸"},
    "2022-10-21": {id: "tt0082971", emojiText: "🏜️🕌💼💀🤠"},
    "2022-10-22": {id: "tt6264654", emojiText: "🎮🔫🧑‍💻⌨️🏦"},
    "2022-10-23": {id: "tt0017136", emojiText: "🤖🌆🇩🇪🎥"},
    "2022-10-24": {id: "tt0120689", emojiText: "💀🚶‍♂️🟩🟩🟩"},
    "2022-10-25": {id: "tt0111161", emojiText: "👨‍⚖️😧♟️⛏️👋"},
    "2022-10-26": {id: "tt0172495", emojiText: "🤼‍♂️🏟️⚔️👎"},
    "2022-10-27": {id: "tt1201607", emojiText: "👦⚡🔺💫🚫👃☠️"},
    "2022-10-28": {id: "tt8772262", emojiText: "💐🔪🐻"},
    "2022-10-29": {id: "tt1853728", emojiText: "🦷🤠🧐🏇⛓️"},
    "2022-10-30": {id: "tt0114814", emojiText: "👮‍♂️🤬🔫🕵️‍♂️❔"},
    "2022-10-31": {id: "tt0095327", emojiText: "👨‍👧⚰️🌌🌂🇯🇵"},
    "2022-11-02": {
        id: "tt7286456",
        emojiText: "🔫🤡🃏"
    },
    "2022-11-03": {
        id: "tt0253474",
        emojiText: "🧑🎹"
    },
    "2022-11-04": {
        id: "tt11703710",
        emojiText: "🎩👒📽️🎙️🇬🇧"
    },
    "2022-11-05": {
        id: "tt0057115",
        emojiText: "🇩🇪🕳️⛏️🏍️🚞"
    },
    "2022-11-06": {
        id: "tt1130884",
        emojiText: "🏝️🧐🏨"
    },
    "2022-11-07": {
        id: "tt0100157",
        emojiText: "⌨️👩‍🦼🔨👩‍🦰"
    },
    "2022-11-08": {
        id: "tt15521050",
        emojiText: "❤️🍦"
    },
    "2022-11-09": {
        id: "tt0076759",
        emojiText: "⭐🔫💣"
    },
    "2022-11-10": {
        id: "tt0081398",
        emojiText: "😡🐂"
    },
    "2022-11-11": {
        id: "tt0092099",
        emojiText: "🔝🔫🛩"
    },
    "2022-11-12": {
        id: "tt0105236",
        emojiText: "🚧🌊🚧🐶"
    },
    "2022-11-13": {
        id: "tt1050263",
        emojiText: "❄◽"
    },
    "2022-11-14": {
        id: "tt0106965",
        emojiText: "🆓🐳"
    },
    "2022-11-15": {
        id: "tt0397892",
        emojiText: "🐶⚡"
    },
    "2022-11-16": {
        id: "tt11245972",
        emojiText: "😱🔪"
    },
    "2022-11-17": {
        id: "tt0118571",
        emojiText: "🛩🇺🇸1"
    },
    "2022-11-18": {
        id: "tt0119654",
        emojiText: "👨🏻⤵⬛👽"
    },
    "2022-11-19": {
        id: "tt0119695",
        emojiText: "💰👄"
    },
    "2022-11-20": {
        id: "tt0120632",
        emojiText: "🏙👼🏻👼🏻"
    },
    "2022-11-21": {
        id: "tt0120812",
        emojiText: "🚗💨🕑"
    },
    "2022-11-22": {
        id: "tt0250494",
        emojiText: "⚖👩🏼👛🎥"
    },
    "2022-11-23": {
        id: "tt0375679",
        emojiText: "🚙💥🚗"
    },
    "2022-11-24": {
        id: "tt0437405",
        emojiText: "📄👨🏻"
    },
    "2022-11-25": {
        id: "tt0417217",
        emojiText: "2➡💰"
    },
    "2022-11-26": {
        id: "tt0112431",
        emojiText: "🐷💬🎥"
    },
    "2022-11-27": {
        id: "tt0086034",
        emojiText: "🐙🐱"
    },
    "2022-11-28": {
        id: "tt6139732",
        emojiText: "👳🏻🔮🌙"
    },
    "2022-11-29": {
        id: "tt0053285",
        emojiText: "💤👩🏻"
    },
    "2022-11-30": {
        id: "tt0096244",
        emojiText: "🍹🌄"
    },
    "2022-12-01": {
        id: "tt0097239",
        emojiText: "🚘👵🏻🌼"
    },
    "2022-12-02": {
        id: "tt0253474",
        emojiText: "👨🏻🎹"
    },
    "2022-12-03": {
        id: "tt9347730",
        emojiText: "🍬👨🏻"
    },
    "2022-12-04": {
        id: "tt0061852",
        emojiText: "🌴📖"
    },
    "2022-12-05": {
        id: "tt0111257",
        emojiText: "💣🚌💨💨"
    },
    "2022-12-06": {
        id: "tt0113117",
        emojiText: "🇫🇷💋"
    },
    "2022-12-07": {
        id: "tt0039536",
        emojiText: "💋💀"
    },
    "2022-12-08": {
        id: "tt0050083",
        emojiText: "12😡👨🏻"
    },
    "2022-12-09": {
        id: "tt1602620",
        emojiText: "👴🏻❤👵🏻"
    },
    "2022-12-10": {
        id: "tt0133152",
        emojiText: "🌍🐵🐵🐵"
    },
    "2022-12-11": {
        id: "tt0120338",
        emojiText: "🛳🌊💥"
    },
    "2022-12-12": {
        id: "tt0157503",
        emojiText: "💧💀👸🏻✨"
    },
    "2022-12-13": {
        id: "tt0161081",
        emojiText: "❓🛌🏻⬇"
    },
    "2022-12-14": {
        id: "tt0163187",
        emojiText: "🏃🏻👰🏻"
    },
    "2022-12-15": {
        id: "tt0175142",
        emojiText: "😱🎬"
    },
    "2022-12-16": {
        id: "tt0448694",
        emojiText: "😺👢"
    },
    "2022-12-17": {
        id: "tt4593060",
        emojiText: "👃🏻➡➡➡"
    },
    "2022-12-18": {
        id: "tt0044706",
        emojiText: "⬆🕛"
    },
    "2022-12-19": {
        id: "tt0250687",
        emojiText: "🐭🏃🏻🏃🏻🏃🏻"
    },
    "2022-12-20": {
        id: "tt0259324",
        emojiText: "👻👨🏻🐎"
    },
    "2022-12-21": {
        id: "tt0259711",
        emojiText: "🍦⛅"
    },
    "2022-12-22": {
        id: "tt0265086",
        emojiText: "⬛🐦⬇"
    },
    "2022-12-23": {
        id: "tt1210819",
        emojiText: "1👨🏻🐴"
    },
    "2022-12-24": {
        id: "tt0960731",
        emojiText: "😴📖"
    },
    "2022-12-25": {
        id: "tt0358273",
        emojiText: "🚶🏻➡➖➖"
    },
    "2022-12-26": {
        id: "tt0360717",
        emojiText: "👑🐵😡🏬"
    },
    "2022-12-27": {
        id: "tt0366548",
        emojiText: "😃👣"
    },
    "2022-12-28": {
        id: "tt0371746",
        emojiText: "ࠦ👨🏻"
    },
    "2022-12-29": {
        id: "tt0377471",
        emojiText: "🐝🆒"
    },
    "2022-12-30": {
        id: "tt0389790",
        emojiText: "🐝🎬"
    },
    "2022-12-31": {
        id: "tt0396269",
        emojiText: "👰🏻🤵🏻💥💥"
    },
    "2023-01-01": {
        id: "tt0401792",
        emojiText: "😈😈😈🌇"
    },
    "2023-01-02": {
        id: "tt0409459",
        emojiText: "⌚👨🏽👨🏽"
    },
    "2023-01-03": {
        id: "tt0415778",
        emojiText: "🔚🎮"
    },
    "2023-01-04": {
        id: "tt0417148",
        emojiText: "🐍🐍🛩"
    },
    "2023-01-05": {
        id: "tt0441773",
        emojiText: "👊🏻💥🐼"
    },
    "2023-01-06": {
        id: "tt0448157",
        emojiText: "🖐🏻🐓"
    },
    "2023-01-07": {
        id: "tt0450259",
        emojiText: "💉💎"
    },
    "2023-01-08": {
        id: "tt0454876",
        emojiText: "👳🏻🚣🏻♂🐅"
    },
    "2023-01-09": {
        id: "tt0472181",
        emojiText: "🔵👴🏻👧🏻👦🏻"
    },
    "2023-01-10": {
        id: "tt0499549",
        emojiText: "🔵👽"
    },
    "2023-01-11": {
        id: "tt0800369",
        emojiText: "⚡🔨💪🏻"
    },
    "2023-01-12": {
        id: "tt0879870",
        emojiText: "🍜🙏🏻❤"
    },
    "2023-01-13": {
        id: "tt0942385",
        emojiText: "🌴🍹⚡☔"
    },
    "2023-01-14": {
        id: "tt0961097",
        emojiText: "👹➡🇫🇷🗼"
    },
    "2023-01-15": {
        id: "tt0978621",
        emojiText: "🏃🏻💥⬅🚂"
    },
    "2023-01-16": {
        id: "tt1605783",
        emojiText: "🌃🕛🇫🇷🗼"
    },
    "2023-01-17": {
        id: "tt1074638",
        emojiText: "⛅🍃"
    },
    "2023-01-18": {
        id: "tt2103264",
        emojiText: "👑🇨🇳"
    },
    "2023-01-19": {
        id: "tt1276104",
        emojiText: "👨🏻🔫👦🏻🔄"
    },
    "2023-01-20": {
        id: "tt1289401",
        emojiText: "👻👻🔫"
    },
    "2023-01-21": {
        id: "tt1416801",
        emojiText: "🔫➡🍀👨🏻"
    },
    "2023-01-22": {
        id: "tt1424381",
        emojiText: "🐆🐅🐊"
    },
    "2023-01-23": {
        id: "tt14403842",
        emojiText: "🔟☔"
    },
    "2023-01-24": {
        id: "tt1860353",
        emojiText: "🐌💨"
    },
    "2023-01-25": {
        id: "tt1637725",
        emojiText: "🐻❤🍻🚬"
    },
    "2023-01-26": {
        id: "tt1790885",
        emojiText: "0🌌30"
    },
    "2023-01-27": {
        id: "tt1798709",
        emojiText: "👨🏻👓❤📱"
    },
    "2023-01-28": {
        id: "tt1885300",
        emojiText: "👌🏻👨🏻⬇"
    },
    "2023-01-29": {
        id: "tt0054215",
        emojiText: "🔪🚿"
    },
    "2023-01-30": {
        id: "tt1981677",
        emojiText: "🎤🎼💯"
    },
    "2023-01-31": {
        id: "tt0099785",
        emojiText: "🏠😱🎄👱🏻"
    },
    "2023-02-01": {
        id: "tt2334879",
        emojiText: "◽🏠⬇"
    },
    "2023-02-02": {
        id: "tt0021749",
        emojiText: "🏙💡💡"
    },
    "2023-02-03": {
        id: "tt0105236",
        emojiText: "🚧🌊🚧🐶"
    },
    "2023-02-04": {
        id: "tt1049413",
        emojiText: "🏠🎈🎈🎈"
    },
    "2023-02-05": {
        id: "tt0068646",
        emojiText: "🇮🇹😇👴🏻"
    },
    "2023-02-06": {
        id: "tt0109830",
        emojiText: "🏃🏻🍫🍤"
    },
    "2023-02-07": {
        id: "tt0137523",
        emojiText: "👊🏻♣"
    },
    "2023-02-08": {
        id: "tt0266543",
        emojiText: "🔎🐠"
    },
    "2023-02-09": {
        id: "tt1375666",
        emojiText: "💡➡😴"
    },
    "2023-02-10": {
        id:  "tt0114709",
        emojiText:  "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-02-11": {
        id:  "tt0167260",
        emojiText:  "💍🌋🧙‍♂️👑"
    },
    "2023-02-12": {
        id:  "tt6139732",
        emojiText:  "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-02-13": {
        id:  "tt0211915",
        emojiText:  "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-02-14": {
        id:  "tt1745960",
        emojiText:  "✈️🇺🇸🕶️"
    },
    "2023-02-15": {
        id:  "tt0109830",
        emojiText:  "🏃🍫🦐"
    },
    "2023-02-16": {
        id:  "tt0120382",
        emojiText:  "🙍‍♂️🎥⛵😨📺"
    },
    "2023-02-17": {
        id:  "tt4633694",
        emojiText:  "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-02-18": {
        id:  "tt0090605",
        emojiText:  "👽🤰🩸😱"
    },
    "2023-02-19": {
        id:  "tt0062622",
        emojiText:  "👁️🖥️🛰️🚀"
    },
    "2023-02-20": {
        id:  "tt0054215",
        emojiText:  "🔪🚿🧓"
    },
    "2023-02-21": {
        id:  "tt0101414",
        emojiText:  "🌹👸🧌"
    },
    "2023-02-22": {
        id:  "tt0264464",
        emojiText:  "🏃‍♂️✈️💰🏃"
    },
    "2023-02-23": {
        id:  "tt0045152",
        emojiText:  "☔🎤😃"
    },
    "2023-02-24": {
        id:  "tt0107290",
        emojiText:  "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-02-25": {
        id:  "tt0382932",
        emojiText:  "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-02-26": {
        id:  "tt10648342",
        emojiText:  "🦸🔨❤️➕⛈️"
    },
    "2023-02-27": {
        id:  "tt0482571",
        emojiText:  "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-02-28": {
        id:  "tt2380307",
        emojiText:  "🎸💀👦🇲🇽"
    },
    "2023-03-01": {
        id:  "tt0107048",
        emojiText:  "🔄⏰🦔"
    },
    "2023-03-02": {
        id:  "tt0133093",
        emojiText:  "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-03-03": {
        id:  "tt1130884",
        emojiText:  "👮‍♂️💊🏝️😨"
    },
    "2023-03-04": {
        id:  "tt0114369",
        emojiText:  "😋😛😴🤢😡😎🤤👀"
    },
    "2023-03-05": {
        id:  "tt5311514",
        emojiText:  "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-03-06": {
        id:  "tt0119217",
        emojiText:  "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-03-07": {
        id:  "tt12412888",
        emojiText:  "🦔👟✌️"
    },
    "2023-03-08": {
        id:  "tt0088763",
        emojiText:  "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-03-09": {
        id:  "tt0435761",
        emojiText:  "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-03-10": {
        id:  "tt0116629",
        emojiText:  "🇺🇸🎆👽👊"
    },
    "2023-03-11": {
        id:  "tt0325980",
        emojiText:  "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-03-12": {
        id:  "tt6467266",
        emojiText:  "🐷🦍🦁🎹🎧🦔"
    },
    "2023-03-13": {
        id:  "tt1160419",
        emojiText:  "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-03-14": {
        id:  "tt0066921",
        emojiText:  "👁️🎩🦯🥛🍊"
    },
    "2023-03-15": {
        id:  "tt1049413",
        emojiText:  "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-03-16": {
        id:  "tt0120338",
        emojiText:  "💑🚢🧊🥶👵"
    },
    "2023-03-17": {
        id:  "tt0050083",
        emojiText:  "😠😠😠😠😠😠😠😠😠😠😠😠"
    },
    "2023-03-18": {
        id:  "tt0073195",
        emojiText:  "🦈😱"
    },
    "2023-03-19": {
        id:  "tt0137523",
        emojiText:  "🥊♣️🧼"
    },
    "2023-03-20": {
        id:  "tt0088247",
        emojiText:  "🤖🕶️🔫"
    },
    "2023-03-21": {
        id:  "tt0110413",
        emojiText:  "🧔🔫🥛👧"
    },
    "2023-03-22": {
        id:  "tt0110912",
        emojiText:  "💼🕺💉💃☕⌚"
    },
    "2023-03-23": {
        id:  "tt0083658",
        emojiText:  "🗡️🏃‍♂️"
    },
    "2023-03-24": {
        id:  "tt4729430",
        emojiText:  "📫🧓🔨🧸🛷"
    },
    "2023-03-25": {
        id:  "tt2382320",
        emojiText:  "🚫🕐💀"
    },
    "2023-03-26": {
        id:  "tt1201607",
        emojiText:  "👦⚡☠️⚔️2️⃣"
    },
    "2023-03-27": {
        id:  "tt0095016",
        emojiText:  "🗼🎄👨🔫"
    },
    "2023-03-28": {
        id:  "tt0041959",
        emojiText:  "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-03-29": {
        id:  "tt0070047",
        emojiText:  "✝️🤮🙏"
    },
    "2023-03-30": {
        id:  "tt0266697",
        emojiText:  "👱‍♀️⛩️⚔️"
    },
    "2023-03-31": {
        id:  "tt0081505",
        emojiText:  "🪓😈🛀"
    },
    "2023-04-01": {
        id:  "tt10298810",
        emojiText:  "👨‍🚀🌌"
    },
    "2023-04-02": {
        id:  "tt0167404",
        emojiText:  "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-04-03": {
        id:  "tt9032400",
        emojiText:  "🦸‍♂️🌍♾️"
    },
    "2023-04-04": {
        id:  "tt0317705",
        emojiText:  "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-04-05": {
        id:  "tt6751668",
        emojiText:  "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-04-06": {
        id:  "tt0266543",
        emojiText:  "🔎🐟"
    },
    "2023-04-07": {
        id:  "tt0075148",
        emojiText:  "🥊"
    },
    "2023-04-08": {
        id:  "tt0112573",
        emojiText:  "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-04-09": {
        id:  "tt0816692",
        emojiText:  "🚀🌊⏲️🌽"
    },
    "2023-04-10": {
        id:  "tt0102926",
        emojiText:  "🤐🐑"
    },
    "2023-04-11": {
        id:  "tt0884328",
        emojiText:  "🌫️🛒🌫️"
    },
    "2023-04-12": {
        id:  "tt1375666",
        emojiText:  "💤🌀⤴️"
    },
    "2023-04-13": {
        id:  "tt0060196",
        emojiText:  "👍👎👹"
    },
    "2023-04-14": {
        id:  "tt0198781",
        emojiText:  "🟢👁️🟢👧👹"
    },
    "2023-04-15": {
        id:  "tt0892769",
        emojiText:  "⚫🐉🦷"
    },
    "2023-04-16": {
        id:  "tt0910970",
        emojiText:  "🌍🗑️🤖🌱❤️"
    },
    "2023-04-17": {
        id:  "tt8503618",
        emojiText:  "🇺🇸🎼🗽🎵🔫"
    },
    "2023-04-18": {
        id:  "tt0457430",
        emojiText:  "👁️👐👁️"
    },
    "2023-04-19": {
        id:  "tt0118715",
        emojiText:  "🎳🥛💼🩲"
    },
    "2023-04-20": {
        id:  "tt0169547",
        emojiText:  "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2023-04-21": {
        id:  "tt0056172",
        emojiText:  "🏇🏜️🗡️"
    },
    "2023-04-22": {
        id:  "tt1345836",
        emojiText:  "🦇🤵⬆️"
    },
    "2023-04-23": {
        id:  "tt0102926",
        emojiText:  "🔇🐏"
    },
    "2023-04-24": {
        id: "tt1375666",
        emojiText: "💡➡😴"
    },
    "2023-04-25": {
        id:  "tt0114709",
        emojiText:  "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-04-26": {
        id:  "tt0167260",
        emojiText:  "💍🌋🧙‍♂️👑"
    },
    "2023-04-27": {
        id:  "tt6139732",
        emojiText:  "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-04-28": {
        id:  "tt0211915",
        emojiText:  "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-04-29": {
        id:  "tt1745960",
        emojiText:  "✈️🇺🇸🕶️"
    },
    "2023-04-30": {
        id:  "tt0109830",
        emojiText:  "🏃🍫🦐"
    },
    "2023-04-31": {
        id:  "tt0120382",
        emojiText:  "🙍‍♂️🎥⛵😨📺"
    },
    "2023-05-01": {
        id:  "tt4633694",
        emojiText:  "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-05-02": {
        id:  "tt0090605",
        emojiText:  "👽🤰🩸😱"
    },
    "2023-05-03": {
        id:  "tt0062622",
        emojiText:  "👁️🖥️🛰️🚀"
    },
    "2023-05-04": {
        id:  "tt0054215",
        emojiText:  "🔪🚿🧓"
    },
    "2023-05-05": {
        id:  "tt0101414",
        emojiText:  "🌹👸🧌"
    },
    "2023-05-06": {
        id:  "tt0264464",
        emojiText:  "🏃‍♂️✈️💰🏃"
    },
    "2023-05-07": {
        id:  "tt0045152",
        emojiText:  "☔🎤😃"
    },
    "2023-05-08": {
        id:  "tt0107290",
        emojiText:  "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-05-09": {
        id:  "tt0382932",
        emojiText:  "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-05-10": {
        id:  "tt10648342",
        emojiText:  "🦸🔨❤️➕⛈️"
    },
    "2023-05-11": {
        id:  "tt0482571",
        emojiText:  "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-05-12": {
        id:  "tt2380307",
        emojiText:  "🎸💀👦🇲🇽"
    },
    "2023-05-13": {
        id:  "tt0107048",
        emojiText:  "🔄⏰🦔"
    },
    "2023-05-14": {
        id:  "tt0133093",
        emojiText:  "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-05-15": {
        id:  "tt1130884",
        emojiText:  "👮‍♂️💊🏝️😨"
    },
    "2023-05-16": {
        id:  "tt0114369",
        emojiText:  "😋😛😴🤢😡😎🤤👀"
    },
    "2023-05-17": {
        id:  "tt5311514",
        emojiText:  "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-05-18": {
        id:  "tt0119217",
        emojiText:  "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-05-19": {
        id:  "tt12412888",
        emojiText:  "🦔👟✌️"
    },
    "2023-05-20": {
        id:  "tt0088763",
        emojiText:  "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-05-21": {
        id:  "tt0435761",
        emojiText:  "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-05-22": {
        id:  "tt0116629",
        emojiText:  "🇺🇸🎆👽👊"
    },
    "2023-05-23": {
        id:  "tt0325980",
        emojiText:  "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-05-24": {
        id:  "tt6467266",
        emojiText:  "🐷🦍🦁🎹🎧🦔"
    },
    "2023-05-25": {
        id:  "tt1160419",
        emojiText:  "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-05-26": {
        id:  "tt0066921",
        emojiText:  "👁️🎩🦯🥛🍊"
    },
    "2023-05-27": {
        id:  "tt1049413",
        emojiText:  "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-05-28": {
        id:  "tt0120338",
        emojiText:  "💑🚢🧊🥶👵"
    },
    "2023-05-29": {
        id:  "tt0050083",
        emojiText:  "😠😠😠😠😠😠😠😠😠😠😠😠"
    },
    "2023-05-30": {
        id:  "tt0073195",
        emojiText:  "🦈😱"
    },
    "2023-05-31": {
        id:  "tt0137523",
        emojiText:  "🥊♣️🧼"
    },
    "2023-06-01": {
        id:  "tt0088247",
        emojiText:  "🤖🕶️🔫"
    },
    "2023-06-02": {
        id:  "tt0110413",
        emojiText:  "🧔🔫🥛👧"
    },
    "2023-06-03": {
        id:  "tt0110912",
        emojiText:  "💼🕺💉💃☕⌚"
    },
    "2023-06-04": {
        id:  "tt0083658",
        emojiText:  "🗡️🏃‍♂️"
    },
    "2023-06-05": {
        id:  "tt4729430",
        emojiText:  "📫🧓🔨🧸🛷"
    },
    "2023-06-06": {
        id:  "tt2382320",
        emojiText:  "🚫🕐💀"
    },
    "2023-06-07": {
        id:  "tt1201607",
        emojiText:  "👦⚡☠️⚔️2️⃣"
    },
    "2023-06-08": {
        id:  "tt0095016",
        emojiText:  "🗼🎄👨🔫"
    },
    "2023-06-09": {
        id:  "tt0041959",
        emojiText:  "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-06-10": {
        id:  "tt0070047",
        emojiText:  "✝️🤮🙏"
    },
    "2023-06-11": {
        id:  "tt0266697",
        emojiText:  "👱‍♀️⛩️⚔️"
    },
    "2023-06-12": {
        id:  "tt0081505",
        emojiText:  "🪓😈🛀"
    },
    "2023-06-13": {
        id:  "tt10298810",
        emojiText:  "👨‍🚀🌌"
    },
    "2023-06-14": {
        id:  "tt0167404",
        emojiText:  "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-06-15": {
        id:  "tt9032400",
        emojiText:  "🦸‍♂️🌍♾️"
    },
    "2023-06-16": {
        id:  "tt0317705",
        emojiText:  "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-06-17": {
        id:  "tt6751668",
        emojiText:  "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-06-18": {
        id:  "tt0266543",
        emojiText:  "🔎🐟"
    },
    "2023-06-19": {
        id:  "tt0075148",
        emojiText:  "🥊"
    },
    "2023-06-20": {
        id:  "tt0112573",
        emojiText:  "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-06-21": {
        id:  "tt0816692",
        emojiText:  "🚀🌊⏲️🌽"
    },
    "2023-06-22": {
        id:  "tt0102926",
        emojiText:  "🤐🐑"
    },
    "2023-06-23": {
        id:  "tt0884328",
        emojiText:  "🌫️🛒🌫️"
    },
    "2023-06-24": {
        id:  "tt1375666",
        emojiText:  "💤🌀⤴️"
    }
};

// try {
//   Object.keys(DAILY_EMOVI).reduce<string[]>((acc, curr) => {
//     if (acc.includes(DAILY_EMOVI[curr].id)) {
//       throw new Error(
//         `Duplicate movie id in DAILY_EMOVI: ${DAILY_EMOVI[curr].id}`
//       );
//     }
//     return acc.concat(DAILY_EMOVI[curr].id);
//   }, []);
// } catch (error) {
//   console.error(error);
// }

const PromoDateDiff = (dt1: any, dt2 = new Date()) => {
    const ret = {days: 0, months: 0, years: 0};

    if (dt1 === dt2) return ret;
    if (dt1 > dt2) {
        const dtmp = dt2;
        dt2 = dt1;
        dt1 = dtmp;
    }

    const year1 = dt1.getFullYear();
    const year2 = dt2.getFullYear();

    const month1 = dt1.getMonth();
    const month2 = dt2.getMonth();

    const day1 = dt1.getDate();
    const day2 = dt2.getDate();
    ret["years"] = year2 - year1;
    ret["months"] = month2 - month1;
    ret["days"] = day2 - day1;

    if (ret["days"] < 0) {
        const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

        const numDays = dtmp1.getDate();

        ret["months"] -= 1;
        ret["days"] += numDays;
    }

    if (ret["months"] < 0) {
        ret["months"] += 12;
        ret["years"] -= 1;
    }

    return ret;
};

interface PromoProps {
    label: string;
    link: string;
}

export const getPromotionalInfo = (): PromoProps => {
    const PromotionalStartDate = new Date(2022, 10, 1);

    const PromotionalInfo = [
        {
            link: "https://semantle.com/junior",
            label: "Try Semantle Jr.!",
        },
        {
            link: "https://wheretaken.teuteuf.fr",
            label: "Try WhereTaken 🌎",
        },
        {
            link: "https://wheretakenusa.teuteuf.fr",
            label: "Try WhereTaken 🇺🇸",
        },
        {
            link: "https://www.thewordfinder.com/word-connection/",
            label: "Try Word Connection",
        },
        {
            link: "https://www.thewordfinder.com/daily-anagram/",
            label: "Try Daily Anagram",
        },
    ];
    const d = PromoDateDiff(PromotionalStartDate);
    let result = PromotionalInfo[0];
    if (d.months > 0) {
        const shuffledPromotions = PromotionalInfo.map((value) => ({
            value,
            sort: Math.random(),
        }))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value);
        result = shuffledPromotions[0];
    }
    return result;
};


function MovieCard({movie}: { movie: Movie }) {
    return (
        <div className="flex flex-col items-center">
            <img className="h-64" src={movie.image} alt="Movie's poster"/>
            <p className="text-lg font-bold text-center">{movie.title}</p>
        </div>
    );
}

function getSavedGuesses() {
    const guesses: Record<string,
        { movieGuessed: boolean; invalidGuessIds: string[] } | undefined> = JSON.parse(localStorage.getItem(GUESSES_KEY) ?? "{}");

    return guesses;
}

function getStats() {
    const allGuesses = getSavedGuesses();

    const streakBonus: Record<string, number> = {
        "2022-07-17": 0,
        "2022-07-18": 1,
        "2022-07-19": 2,
        "2022-07-20": 3,
        "2022-07-21": 4,
        "2023-04-23": 5,
        "2023-04-24": 6,
        "2023-05-24": 6,
    };

    const days = Object.keys(allGuesses);
    let currentStreak = days.length > 0 ? streakBonus[days[0]] ?? 0 : 0;
    let maxStreak = 0;
    let previousDate: DateTime | undefined;
    for (const [dayString, guesses] of Object.entries(allGuesses)) {
        const currentDate = DateTime.fromFormat(dayString, "yyyy-MM-dd");
        if (guesses!.movieGuessed) {
            if (
                previousDate == null ||
                previousDate.plus({days: 1}).hasSame(currentDate, "day")
            ) {
                currentStreak++;
            } else {
                currentStreak = 1;
            }
        } else {
            if (['2022-10-01', '2022-10-02', '2022-11-28',"2023-04-24","2023-04-25"].includes(dayString)) {
                currentStreak++;
            } else {
                currentStreak = 0;
            }
        }

        if (currentStreak > maxStreak) {
            maxStreak = currentStreak;
        }
        previousDate = currentDate;
    }

    return {
        currentStreak,
        maxStreak,
    };
}

function GuessAEmovi({
                         emoviToGuess,
                         dailyNumber,
                         dayString,
                     }: {
    emoviToGuess: EmoviToGuess;
    dailyNumber?: number;
    dayString?: string;
}) {
    const movieToGuess = useMemo(() => {
        return emoviToGuess && allMovies.find((m) => m.id === emoviToGuess.id);
    }, [emoviToGuess]);

    useEffect(() => {
        console.log(emoviToGuess);
    }, [emoviToGuess]);

    const selectOptions = useMemo(() => {
        return allMovies.map((m) => ({
            value: m.id,
            label: m.title,
        }));
    }, []);

    const [selectedOption, setSelectedOption] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const initialSavedGuess = useMemo(() => {
        if (!dayString) {
            return undefined;
        }

        const savedGuesses = getSavedGuesses();
        return savedGuesses[dayString];
    }, [dayString]);
    const [invalidGuessIds, setInvalidGuessIds] = useState<string[]>(
        initialSavedGuess?.invalidGuessIds ?? []
    );
    const [movieGuessed, setMovieGuessed] = useState<boolean>(
        initialSavedGuess?.movieGuessed ?? false
    );
    const movieFailed = invalidGuessIds.length >= MAX_TRIES;
    const handleGetHint = useCallback(() => {
        setInvalidGuessIds((prev) => [...prev, ""]);
    }, []);
    const selectMovieRef = useRef<any>(null);
    const handleGuess = useCallback(() => {
        if (!selectedOption) {
            selectMovieRef.current?.focus();
            return;
        }
        if (emoviToGuess && emoviToGuess.id === selectedOption?.value) {
            setMovieGuessed(true);
            toast.success("You guessed it!");
        } else {
            setInvalidGuessIds((prev) => [...prev, selectedOption?.value]);
            toast.error("Wrong movie!");
            setSelectedOption(null);
        }
    }, [emoviToGuess, selectedOption]);

    const [stats, setStats] = useState(getStats());

    useEffect(() => {
        if (!dayString) {
            return;
        }

        const guesses = getSavedGuesses();

        if (!guesses[dayString]) {
            guesses[dayString] = {
                movieGuessed: false,
                invalidGuessIds: [],
            };
        }

        guesses[dayString]!.movieGuessed = movieGuessed;
        guesses[dayString]!.invalidGuessIds = invalidGuessIds;

        localStorage.setItem(GUESSES_KEY, JSON.stringify(guesses));
        setStats(getStats());
    }, [dayString, invalidGuessIds, movieGuessed]);

    const shareText = useMemo(() => {
        if (!emoviToGuess || !movieToGuess) {
            return "";
        }

        return [
            `#Emovi 🎬${dailyNumber ? ` #${dailyNumber}` : ""}`,
            emoviToGuess.emojiText,
            "🟥".repeat(invalidGuessIds.length) +
            (movieGuessed ? "🟩" : "") +
            "⬜".repeat(Math.max(MAX_TRIES - invalidGuessIds.length - 1, 0)),
            dayString ? window.location.origin : buildShareUrl(emoviToGuess),
        ].join("\n");
    }, [
        dailyNumber,
        dayString,
        emoviToGuess,
        invalidGuessIds.length,
        movieGuessed,
        movieToGuess,
    ]);

    return (
        <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex flex-col items-center w-full">
                <p className="text-lg font-bold text-center">Guess this movie:</p>
                {emoviToGuess && (
                    <p className="text-3xl text-center">
                        <Twemoji
                            text={emoviToGuess.emojiText}
                            options={{className: "inline-block"}}
                        />
                    </p>
                )}
                {emoviToGuess.author && (
                    <p className="text-center">
                        by{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-semibold"
                            href={`https://twitter.com/${emoviToGuess.author}`}
                        >
                            {emoviToGuess.author}
                        </a>
                    </p>
                )}
            </div>
            {!movieGuessed && !movieFailed ? (
                <div className="flex flex-col gap-4 items-center w-full">
                    <Select
                        ref={selectMovieRef}
                        openMenuOnFocus
                        openMenuOnClick
                        className="w-full"
                        options={selectOptions}
                        onChange={setSelectedOption}
                        value={selectedOption}
                    />
                    <div className="flex flex-row-reverse gap-2 w-full ">
                        <button
                            onClick={handleGuess}
                            className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-300 text-white font-bold py-4 px-4 rounded basis-2/3"
                        >
                            Guess! ({invalidGuessIds.length + 1} / {MAX_TRIES})
                        </button>
                        <button
                            onClick={handleGetHint}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-4 rounded basis-1/3"
                        >
                            {invalidGuessIds.length >= MAX_TRIES - 1
                                ? "Give up..."
                                : "Get a hint..."}
                        </button>
                    </div>
                    {invalidGuessIds.length > 0 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #1 - Year:
                            </div>
                            <div className="col-span-2">{movieToGuess?.year}</div>
                        </div>
                    )}
                    {invalidGuessIds.length > 1 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #2 - Crew:
                            </div>
                            <div className="col-span-2">{movieToGuess?.crew}</div>
                        </div>
                    )}
                    <div className="mt-16 flex flex-col gap-2 items-center">
                        {!dailyNumber && (
                            <>
                                <Link
                                    to="/"
                                    className="text-blue-500 hover:text-blue-700 font-bold"
                                >
                                    Go to the daily Emovi
                                </Link>
                                <p>or</p>
                            </>
                        )}
                        <Link
                            to="/make"
                            className="text-blue-500 hover:text-blue-700 font-bold"
                        >
                            Create your own Emovi
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-2 text-center w-full">
                    {movieToGuess && <MovieCard movie={movieToGuess}/>}
                    <div>
                        {movieGuessed ? (
                            <>
                                <p>Well done! You guessed the movie!</p>
                                <p>In {invalidGuessIds.length + 1} guesses!</p>
                            </>
                        ) : (
                            <p>You failed to guess the movie...</p>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Result copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                                Share your result
                            </button>
                        </CopyToClipboard>
                        <Link
                            to="/make"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        >
                            Create your Emovi
                        </Link>
                        {!dailyNumber && (
                            <Link
                                to="/"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Go to the daily Emovi
                            </Link>
                        )}
                    </div>
                    {dayString && (
                        <div className="py-4 font-bold">
                            Current streak: {stats.currentStreak} - Max streak:{" "}
                            {stats.maxStreak}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

function DailyEmoviRoute() {
    const migrationUrl = useMemo(() => {
        if (window.location.hostname !== "localhost") {
            return null;
        }
        const guesses = localStorage.getItem("guesses");
        if (!guesses) {
            return null;
        }
        const compressedGuesses = compressToEncodedURIComponent(guesses);
        return `https://play.emovi.fun/import/${compressedGuesses}`;
    }, []);

    const dayString = getDayString();
    const dailyNumber =
        Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
            "day"
        ) + 1;
    const emoviToGuess = DAILY_EMOVI[dayString];
    return emoviToGuess ? (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold text-lg">Emovi #{dailyNumber}</p>
            <GuessAEmovi
                emoviToGuess={emoviToGuess}
                dailyNumber={dailyNumber}
                dayString={dayString}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold">No daily emovi for today!</p>
            <p className="text-center">
                <Link
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    to="/make"
                >
                    Create your own Emovi
                </Link>{" "}
                or check{" "}
                <a
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    href="https://twitter.com/search/?q=%23MyEmovi"
                >
                    #MyEmovi
                </a>{" "}
                on Twitter!
            </p>
        </div>
    );
}

function MakeAEmoviRoute() {
    const [movieToGuess, setMovieToGuess] = useState(pickRandomMovie());
    const [emojiText, setEmojiText] = useState("");
    const [validated, setValidated] = useState(false);

    const shareUrl = useMemo(
        () => buildShareUrl({id: movieToGuess.id, emojiText}),
        [emojiText, movieToGuess.id]
    );

    const handleNewEmovi = useCallback(() => {
        setMovieToGuess(pickRandomMovie());
        setEmojiText("");
        setValidated(false);
    }, []);

    const shareText = useMemo(() => {
        return [
            "#Emovi 🎬 #MyEmovi",
            "Guess this movie:",
            emojiText,
            shareUrl,
        ].join("\n");
    }, [emojiText, shareUrl]);

    return (
        <div className="w-full">
            <div className="flex flex-col items-center gap-2 w-full">
                <MovieCard movie={movieToGuess}/>
                {!validated && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => {
                            setMovieToGuess(pickRandomMovie());
                        }}
                    >
                        Pick another movie...
                    </button>
                )}
            </div>
            <div className="flex flex-col gap-1 items-center">
                <div className="flex flex-col gap-2 justify-center items-center grow shrink-0 basis-32">
                    <p className="text-xl text-center">
                        {emojiText ? (
                            <Twemoji
                                text={emojiText}
                                options={{className: "inline-block text-3xl"}}
                            />
                        ) : (
                            <span> Describe movie with emoji!</span>
                        )}
                    </p>
                    {emojiText && !validated && (
                        <div className="flex gap-2">
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => {
                                    setEmojiText("");
                                }}
                            >
                                Clear
                            </button>
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setValidated(true)}
                            >
                                Validate
                            </button>
                        </div>
                    )}
                </div>
                {!validated ? (
                    <Picker
                        set="twitter"
                        showSkinTones={false}
                        showPreview
                        onSelect={(e: any) => {
                            setEmojiText((prev) => prev + e.native);
                        }}
                    />
                ) : (
                    <div className="flex flex-col gap-1 w-full text-center">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Emovi copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                Share my Emovi
                            </button>
                        </CopyToClipboard>
                        <button
                            onClick={handleNewEmovi}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Create another Emovi
                        </button>
                        <Link
                            to="/"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Go to the daily Emovi
                        </Link>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-bold text-center py-4"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSevIwncXHGD4K4yPBHi3Z2P0ynzmqaWZccfCCnzitN5xei54g/viewform?usp=sf_link&hl=en"
                        >
                            Suggest a daily Emovi
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

const MAX_TRIES = 3;

function GuessAEmoviRoute() {
    const {emovi: encodedEmovi} = useParams<{ emovi: string }>();
    const emoviToGuess: EmoviToGuess | undefined = useMemo(
        () => (encodedEmovi ? decode(encodedEmovi) : undefined),
        [encodedEmovi]
    );
    const dayString = getDayString();
    const dailyEmovi = DAILY_EMOVI[dayString];

    if (
        dailyEmovi &&
        emoviToGuess &&
        dailyEmovi.id === emoviToGuess.id &&
        dailyEmovi.emojiText === emoviToGuess.emojiText
    ) {
        return <Navigate to="/"/>;
    }

    return emoviToGuess ? (
        <GuessAEmovi emoviToGuess={emoviToGuess}/>
    ) : (
        <div>Oops...</div>
    );
}

function ImportSaveRoute() {
    const navigate = useNavigate();
    const {save: encodedSave} = useParams<{ save: string }>();
    useEffect(() => {
        if (!encodedSave) {
            return;
        }

        const save = decompressFromEncodedURIComponent(encodedSave);
        if (!save) {
            return;
        }

        localStorage.setItem(GUESSES_KEY, save);
        navigate("/");
    }, [encodedSave, navigate]);

    return null;
}

const AppContent = () => {
    const promotionalInfo = getPromotionalInfo();
    return (
        <>
            <header className="text-4xl font-bold text-center w-full border-b-2 border-gray-200">
                <div className="w-full my-1">
                    <Link to="/">
                        <Twemoji
                            text="🎬 EMOVI 🎥"
                            options={{className: "inline-block"}}/>
                    </Link>
                </div>
            </header>
            <div className="flex justify-center items-center w-full pt-1 pb-1 lg:hidden xl:hidden md:hidden sm:visible" style={{maxHeight: "50px"}}>
                <div id="adngin-top_banner-0"></div>
            </div>
            <div className="flex-grow w-full p-1">
                <Routes>
                    <Route path="/" element={<DailyEmoviRoute/>}/>
                    <Route path="/make" element={<MakeAEmoviRoute/>}/>
                    <Route path="/guess/:emovi" element={<GuessAEmoviRoute/>}/>
                    <Route path="/import/:save" element={<ImportSaveRoute/>}/>
                </Routes>
            </div>
            <footer className="flex justify-center flex-col mt-8 mb-4 gap-2" 
                style={{paddingBottom: "100px !important"}}>
                <div className="flex justify-center items-center">
                    <Twemoji
                        text="❤️ EMOVI 🎥 ?"
                        className="flex gap-1 items-center justify-center mr-1 font-bold"/>{" "}
                    -
                    <a
                        className="underline pl-2"
                        href={promotionalInfo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="w-max">{"" + promotionalInfo.label}</div>
                    </a>
                </div>
            </footer>
        </>
    )
}

function App() {

    return (
        <>

        <div className="flex justify-center flex-auto flex-row  relative overflow-hidden"
                style={{ height: "100%", position: "relative"}}
              >
            
                <div className="flex flex-col w-full max-w-lg relative">         
                    <ToastContainer
                      hideProgressBar
                      position="top-center"
                      transition={Flip}
                      theme="light"
                      autoClose={2000}
                      bodyClassName="font-bold text-center"
                      toastClassName="flex justify-center m-2 max-w-full"
                      style={{width: 500, maxWidth: "100%"}}/>       
                    <AppContent/>
                    <div
                        className="absolute left-0 -translate-x-full snigel-sidev"
                        style={{ zIndex: 200, maxWidth: "330px" }}
                      >
                        <div
                          style={{
                            position: "relative",
                            zIndex: 10000,
                            width: "300px",
                            display: "flex",
                            marginTop: "15px",
                            marginRight: "30px",
                            minHeight: "200px",
                            justifyContent: "flex-end",
                            pointerEvents: "all",
                          }}
                        >
                          <div id="adngin-sidebar_left-0"></div>
                        </div>
                      </div>
                      <div
                        className="absolute right-0 translate-x-full snigel-sidev"
                        style={{ zIndex: 200, maxWidth: "330px" }}
                      >
                        <div
                          style={{
                            position: "relative",
                            zIndex: 10000,
                            marginTop: "15px",
                            marginLeft: "30px",
                            width: "300px",
                            display: "flex",
                            minHeight: "200px",
                            justifyContent: "flex-start",
                            pointerEvents: "all",
                          }}
                        >
                          <div id="adngin-sidebar_right-0"></div>
                        </div>
                      </div>
                </div>           
          </div>
        </>
    );
}

export default App;
